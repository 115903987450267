import React, { ChangeEvent, useEffect, useState } from "react";
import * as Joi from "joi";
import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
  Chip,
  InputLabel,
  Autocomplete,
  TextField,
  Button,
  Checkbox,
  Grid,
  FormGroup,
  Alert as MuiAlert,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { parse, isValid } from "date-fns";
import csv from "csvtojson";
import { json2csvAsync } from "json-2-csv";
import { ProjectModel } from "import-automation-tool-backend/src/models/project.model";
import { EcmProductUniversalAttributeModel } from "import-automation-tool-backend/src/models/ecmProductUniversalAttribute.model";
import { EcmCategoryTreeNodeModel } from "import-automation-tool-backend/src/models/ecmCategoryTreeNode.model";
import { EcmLocationModel } from "import-automation-tool-backend/src/models/ecmLocation.model";
import { EcmEnvironmentModel } from "import-automation-tool-backend/src/models/ecmEnvironment.model";
import { EcmDealerInfoModel } from "import-automation-tool-backend/src/models/ecmDealerInfo.model";
import { EcmMarketplaceAccountModel } from "import-automation-tool-backend/src/models/ecmMarketplaceAccount.model";
import { ActionModel } from "import-automation-tool-backend/src/models/action/action.model";
import { MarketplaceNoteModel } from "import-automation-tool-backend/src/models/marketplaceNote.model";
import { ActionTypeModel } from "import-automation-tool-backend/src/models/action/type.model";

import "./index.css";
import ApiService from "../../services/api.service";
import { Alert, ALERT_STATUS, AlertContent } from "../../components/Alert";
import SelectComponent from "../../components/Select";
import ConfirmationWindow from "../../components/ConfirmationWindow";
import LoadingWindow from "../../components/LoadingWindow";
import ActionListComponent from "../../components/Actions";
import ButtonGroupComponent from "../../components/ButtonGroup";
import CreateProjectWindow from "../../components/CreateProjectWindow";
import { CSVBoxModalButton } from "../../components/CSVBoxModalButton";

import { ScrapingError } from "../../utils/scraping.error";
import TagInputComponent from "../../components/TagInput";

const CSVBOX_LICENSE_KEY = process.env.REACT_APP_CSVBOX_LICENSE_KEY;

export const AlertContext = React.createContext<
  | {
      setAlert: (content: AlertContent | null) => void;
    }
  | undefined
>(undefined);

interface MainProps {
  user: any;
}

export default function Main({ user }: MainProps) {
  const apiService = new ApiService();

  const emailValidator = Joi.string().email({ tlds: { allow: false } });

  //ECM env
  const [ecmEnvsData, setEcmEnvsData] = useState<EcmEnvironmentModel[]>([]);
  const [ecmEnvsLoading, setEcmEnvsLoading] = useState<boolean>(false);
  const [ecmEnvsSelectedItem, setEcmEnvsSelectedItem] =
    useState<EcmEnvironmentModel | null>(null);

  //project
  const [projectsData, setProjectsData] = useState<ProjectModel[]>([]);
  const [projectsLoading, setProjectsLoading] = useState<boolean>(false);
  const [projectsSelectedItem, setProjectsSelectedItem] =
    useState<ProjectModel | null>(null);

  //project dealer
  const [projectDealerData, setProjectDealerData] =
    useState<EcmDealerInfoModel | null>(null);
  const [projectDealerLoading, setProjectDealerLoading] = useState<any>(false);

  //dealers
  const [dealersData, setDealersData] = useState<any[]>([]);
  const [dealersLoading, setDealersLoading] = useState<boolean>(false);

  //marketplace accounts
  const [marketplaceAccountsData, setMarketplaceAccountsData] = useState<
    EcmMarketplaceAccountModel[]
  >([]);
  const [marketplaceAccountsLoading, setMarketplaceAccountsLoading] =
    useState<boolean>(false);
  const [marketplaceAccountsSelectedItem, setMarketplaceAccountsSelectedItem] =
    useState<EcmMarketplaceAccountModel | null>(null);

  //project actions
  const [projectActionsData, setProjectActionsData] = useState<ActionModel[]>(
    [],
  );
  const [projectActionsLoading, setProjectActionsLoading] =
    useState<boolean>(false);

  //alerts
  const [alert, setAlert] = useState<AlertContent | null>(null);

  //modals
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [showRemoveListingsModal, setShowRemoveListingsModal] =
    useState<boolean>(false);
  const [showMassMergeModal, setShowMassMergeModal] = useState<boolean>(false);
  const [showImportStatusesModal, setShowImportStatusesModal] =
    useState<boolean>(false);
  const [showAnonymousImportModal, setShowAnonymousImportModal] =
    useState<boolean>(false);
  const [showImportUploadFileModal, setShowImportUploadFileModal] =
    useState<boolean>(false);
  const [showCSVBoxImportModal, setShowCSVBoxImportModal] =
    useState<boolean>(false);
  const [showImportUploadCSVFileModal, setShowImportUploadCSVFileModal] =
    useState<boolean>(false);
  const [CSVReport, setCSVReport] = useState<{
    [key: string]: { type: "error" | "warning" | "info"; cnt: number };
  }>({});
  const [CSVColumnReport, setCSVColumnReport] = useState<
    { msg: string; type: "error" | "warning" }[]
  >([]);
  const [CSVFileProcessedData, setCSVFileProcessedData] = useState<object[]>(
    [],
  );
  const [processedDataForImport, setProcessedDataForImport] = useState<
    object[]
  >([]);
  const [CSVCriticalError, setCSVCriticalError] = useState<boolean>(false);

  const emptyFile = new File([new Blob()], "please select file");
  const [fileContent, setFileContent] = useState<File>(emptyFile);

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);

  const [massMergeConnectedAccounts, setMassMergeConnectedAccounts] =
    React.useState<string[]>([]);
  const [massMergeMode, setMassMergeMode] = useState<number>(0);
  //); // placeholder for future's second field
  const [startRetrieveMPStatuses, setStartRetrieveMPStatuses] = useState<
    string[]
  >([]);
  const [startImportMPStatuses, setStartImportMPStatuses] = useState<string[]>(
    [],
  );
  const [listingIds, setListingIds] = useState<string[]>([]);

  const [marketplaceNotes, setMarketplaceNotes] =
    useState<MarketplaceNoteModel | null>(null);
  const [marketplaceNotesLoading, setMarketplaceNotesLoading] =
    useState<boolean>(false);

  // marketplace statuses during start import
  const [marketplaceRetrieveAllStatuses, setMarketplaceRetrieveAllStatuses] =
    useState<string[]>([]);
  const [
    marketplaceRetrieveDefaultStatuses,
    setMarketplaceRetrieveDefaultStatuses,
  ] = useState<string[]>([]);

  const [marketplaceImportAllStatuses, setMarketplaceImportAllStatuses] =
    useState<string[]>([]);

  const [isSingularJob, setIsSingularJob] = useState<boolean>(true);

  //credentials ECM env
  const [credentialsEcmEnvSelectedItem, setCredentialsEcmEnvSelectedItem] =
    useState<EcmEnvironmentModel | null>(null);

  //credentials dealer
  const [credentialsDealerSelectedItem, setCredentialsDealerSelectedItem] =
    useState<EcmDealerInfoModel | null>(null);
  const [credentialsDealerLoading, setCredentialsDealerLoading] =
    useState<any>(false);
  const [credentialsDealerData, setCredentialsDealerData] = useState<any[]>([]);

  const [useCredentialsAccount, setUseCredentialsAccount] =
    useState<boolean>(false);

  const [credentialsMarketplaceAccount, setCredentialsMarketplaceAccount] =
    useState<EcmMarketplaceAccountModel | null>(null);

  const [importIdCustomPrefix, setImportIdCustomPrefix] = useState<string>("");

  // marketplaceDealerId is used for anonymous import of the dealer to ECM
  const [marketplaceDealerId, setMarketplaceDealerId] = useState<string>("");

  const [inventoryManagerDealerId, setInventoryManagerDealerId] =
    useState<string>("");

  const apiCall = async (
    handler: () => Promise<any>,
    defaultResult: any,
    errorMessage: string | null,
    successMessage: string | null,
  ) => {
    try {
      const result = await handler();

      if (successMessage) {
        setAlert({ type: ALERT_STATUS.SUCCESS, message: successMessage });
      }

      return result || defaultResult;
    } catch (error) {
      if (errorMessage) {
        const alertContent: AlertContent = {
          type: ALERT_STATUS.ERROR,
          message: `${errorMessage}, error: [${error}]`,
        };

        if (error instanceof ScrapingError) {
          alertContent.linkUrl = error.statusPageUrl;
        }

        setAlert(alertContent);
      }

      return defaultResult;
    }
  };

  const loadEcmEnvs = async () => {
    setEcmEnvsSelectedItem(null);
    setEcmEnvsLoading(true);
    setEcmEnvsData([]);

    const result: EcmEnvironmentModel[] = await apiCall(
      async () => {
        return await apiService.listEcmEnvs();
      },
      [],
      "Can't load ECM environments",
      null,
    );

    setEcmEnvsLoading(false);
    setEcmEnvsData(result);

    if (localStorage.getItem("ecmEnv")) {
      const filteredEnv = result.filter(
        item => item.env === localStorage.getItem("ecmEnv"),
      )[0];

      if (filteredEnv) {
        setEcmEnvsSelectedItem(filteredEnv);
      }
    }
  };

  const loadEcmProjects = async () => {
    setProjectsData([]);
    setProjectsSelectedItem(null);

    if (ecmEnvsSelectedItem) {
      setProjectsLoading(true);

      const result: ProjectModel[] = await apiCall(
        async () => {
          return await apiService.listProjects(ecmEnvsSelectedItem.env);
        },
        [],
        "Can't load projects",
        null,
      );

      setProjectsLoading(false);
      setProjectsData(result);

      if (localStorage.getItem("ecmProject")) {
        const filteredItem = result.filter(
          item => item.id === localStorage.getItem("ecmProject"),
        )[0];

        if (filteredItem) {
          setProjectsSelectedItem(filteredItem);
        }
      }
    }
  };

  const loadEcmDealers = async () => {
    if (showCreateModal && dealersData.length === 0) {
      setDealersData([]);

      if (ecmEnvsSelectedItem) {
        setDealersLoading(true);

        const result = await apiCall(
          async () => {
            return await apiService.listEcmDealers(ecmEnvsSelectedItem.env);
          },
          [],
          "Can't load ECM dealers",
          null,
        );

        setDealersLoading(false);
        setDealersData(result);
      }
    }
  };

  const loadProjectEcmDealer = async () => {
    setProjectDealerData(null);

    if (ecmEnvsSelectedItem && projectsSelectedItem) {
      setProjectDealerLoading(true);

      const result = await apiCall(
        async () => {
          return await apiService.getEcmDealer(
            ecmEnvsSelectedItem.env,
            projectsSelectedItem.dealerId,
          );
        },
        null,
        "Can't load ECM dealer",
        null,
      );

      setProjectDealerLoading(false);
      setProjectDealerData(result);
    }
  };

  const isSpiderMarketplace = () => {
    // this code assumes that all spider marketplaces have retrieve statuses in the configuration
    return (
      marketplaceRetrieveAllStatuses &&
      marketplaceRetrieveAllStatuses.length > 0
    );
  };

  const loadMarketplaceAccounts = async () => {
    setMarketplaceAccountsData([]);
    setMarketplaceAccountsSelectedItem(null);

    if (ecmEnvsSelectedItem && projectsSelectedItem) {
      setMarketplaceAccountsLoading(true);

      const result: EcmMarketplaceAccountModel[] = await apiCall(
        async () => {
          return await apiService.listDealerAccounts(
            ecmEnvsSelectedItem.env,
            projectsSelectedItem.dealerId,
          );
        },
        [],
        "Can't fetch marketplace accounts",
        null,
      );

      //add ability to import inventory products without braking all ui
      result.unshift({
        accountId: `${projectsSelectedItem.dealerId}.ronati.000`,
        code: "ronati",
        name: "Products Inventory",
        status: "validated",
        tokenStatus: "validated",
      });

      setMarketplaceAccountsLoading(false);
      setMarketplaceAccountsData(result);

      if (localStorage.getItem("accountId")) {
        const filteredItem = result.filter(
          item => item.accountId === localStorage.getItem("accountId"),
        )[0];

        if (filteredItem) {
          setMarketplaceAccountsSelectedItem(filteredItem);
        }
      }
    }
  };

  const removeEcmProject = async () => {
    setShowLoadingModal(true);

    await apiCall(
      async () => {
        if (!ecmEnvsSelectedItem) {
          throw new Error("ECM environment not selected");
        }

        if (!projectsSelectedItem) {
          throw new Error("Project not selected");
        }

        await apiService.deleteProject(
          ecmEnvsSelectedItem.env,
          projectsSelectedItem.id,
        );
      },
      null,
      "Can't remove project",
      `Project "${projectsSelectedItem?.name}" removed`,
    );

    await loadEcmProjects();
    setShowLoadingModal(false);
  };

  const createEcmProject = async (ecmProject: ProjectModel) => {
    setShowLoadingModal(true);

    await apiCall(
      async () => {
        if (!ecmEnvsSelectedItem) {
          throw new Error("ECM environment not selected");
        }

        await apiService.createProject(ecmEnvsSelectedItem.env, ecmProject);
      },
      null,
      "Can't create project",
      `Project "${ecmProject?.name}" created`,
    );

    await loadEcmProjects();
    setShowLoadingModal(false);
  };

  const loadProjectActions = async () => {
    setProjectActionsData([]);

    if (
      ecmEnvsSelectedItem &&
      projectsSelectedItem &&
      marketplaceAccountsSelectedItem
    ) {
      setProjectActionsLoading(true);

      const result = await apiCall(
        async () => {
          return await apiService.listActions(
            ecmEnvsSelectedItem.env,
            projectsSelectedItem.id,
            marketplaceAccountsSelectedItem.accountId,
          );
        },
        [],
        "Can't fetch project actions",
        null,
      );

      setProjectActionsLoading(false);
      setProjectActionsData(result);
    }
  };

  const getMarketplaceInventory = async () => {
    setShowLoadingModal(true);

    await apiCall(
      async () => {
        if (!ecmEnvsSelectedItem) {
          throw new Error("ECM environment not selected");
        }

        if (!projectsSelectedItem) {
          throw new Error("Project not selected");
        }

        if (!marketplaceAccountsSelectedItem) {
          throw new Error("Marketplace account not selected");
        }

        let credentialsAccountId: string = null;
        let credentialsEcmEnv: string = null;

        if (useCredentialsAccount) {
          credentialsAccountId = credentialsMarketplaceAccount
            ? credentialsMarketplaceAccount.accountId
            : null;

          credentialsEcmEnv = credentialsEcmEnvSelectedItem?.env || null;

          if (!credentialsAccountId || !credentialsEcmEnv) {
            throw new Error(
              "Both credentials ECM env and Dealer account should be selected",
            );
          }
        }

        await apiService.getMarketplaceInventory(
          ecmEnvsSelectedItem.env,
          projectsSelectedItem.id,
          marketplaceAccountsSelectedItem.accountId,
          credentialsAccountId,
          credentialsEcmEnv,
        );
      },
      null,
      "Can't fetch marketplace inventory",
      `Created job to fetch marketplace inventory`,
    );

    await loadProjectActions();

    setShowLoadingModal(false);
  };

  const getMarketplaceInventoryTooltip = () => {
    if (!isMarketplaceAccountCredentialsSet()) {
      return "Credentials not set";
    }

    if (isExistsPendingActions("MarketplaceInventory")) {
      return "MarketplaceInventory action already in pending state";
    }

    return "";
  };

  const getEcmInventory = async () => {
    setShowLoadingModal(true);

    await apiCall(
      async () => {
        if (!ecmEnvsSelectedItem) {
          throw new Error("ECM environment not selected");
        }

        if (!projectsSelectedItem) {
          throw new Error("Project not selected");
        }

        if (!marketplaceAccountsSelectedItem) {
          throw new Error("Marketplace account not selected");
        }

        await apiService.getEcmInventory(
          ecmEnvsSelectedItem.env,
          projectsSelectedItem.id,
          marketplaceAccountsSelectedItem.accountId,
        );
      },
      null,
      "Can't fetch ECM inventory",
      `Created job to fetch ECM inventory`,
    );

    await loadProjectActions();

    setShowLoadingModal(false);
  };

  const removeListings = async () => {
    setShowLoadingModal(true);

    await apiCall(
      async () => {
        if (!ecmEnvsSelectedItem) {
          throw new Error("ECM environment not selected");
        }

        if (!projectsSelectedItem) {
          throw new Error("Project not selected");
        }

        if (!marketplaceAccountsSelectedItem) {
          throw new Error("Marketplace account not selected");
        }

        await apiService.removeListings(
          ecmEnvsSelectedItem.env,
          projectsSelectedItem.id,
          marketplaceAccountsSelectedItem.accountId,
        );
      },
      null,
      "Can't remove listings",
      `Listings removed`,
    );

    await loadProjectActions();

    setShowLoadingModal(false);
  };

  const massMergeProducts = async () => {
    setShowLoadingModal(true);

    await apiCall(
      async () => {
        if (!ecmEnvsSelectedItem) {
          throw new Error("ECM environment not selected");
        }

        if (!projectsSelectedItem) {
          throw new Error("Project not selected");
        }

        if (!marketplaceAccountsSelectedItem) {
          throw new Error("Marketplace account not selected");
        }

        const accountIds = massMergeConnectedAccounts;

        const mergeMode = massMergeMode;

        await apiService.massMergeProducts(
          ecmEnvsSelectedItem.env,
          projectsSelectedItem.id,
          accountIds,
          mergeMode,
        );
      },
      null,
      "Can't execute mass merge",
      `Mass Merge executed`,
    );

    await loadProjectActions();

    setShowLoadingModal(false);
  };

  const calculateExpectingResults = async () => {
    setShowLoadingModal(true);

    await apiCall(
      async () => {
        if (!ecmEnvsSelectedItem) {
          throw new Error("ECM environment not selected");
        }

        if (!projectsSelectedItem) {
          throw new Error("Project not selected");
        }

        if (!marketplaceAccountsSelectedItem) {
          throw new Error("Marketplace account not selected");
        }

        await apiService.expectedResultsAction(
          ecmEnvsSelectedItem.env,
          projectsSelectedItem.id,
          marketplaceAccountsSelectedItem.accountId,
          null,
        );
      },
      null,
      "Can't start Expected results action",
      `Expected results action executed`,
    );

    await loadProjectActions();
    setShowLoadingModal(false);
  };

  const marketplaceImportWithFile = async () => {
    setShowLoadingModal(true);

    await apiCall(
      async () => {
        if (!ecmEnvsSelectedItem) {
          throw new Error("ECM environment not selected");
        }

        if (!projectsSelectedItem) {
          throw new Error("Project not selected");
        }

        if (!marketplaceAccountsSelectedItem) {
          throw new Error("Marketplace account not selected");
        }

        if (fileContent.size === 0) {
          throw new Error("file was not selected or is empty");
        }

        const result = await apiService.marketplaceImportWithFile(
          ecmEnvsSelectedItem.env,
          projectsSelectedItem.id,
          marketplaceAccountsSelectedItem.accountId,
          null,
          true,
        );

        await apiService.uploadFile(fileContent, result.data.uploadFileUrl);
      },
      null,
      "Can't start import",
      `Created import job`,
    );

    await loadProjectActions();

    setShowLoadingModal(false);
  };

  const download = function (data: string) {
    const blob = new Blob([data], { type: "text/csv" });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");

    a.setAttribute("href", url);
    a.setAttribute("download", "errors.csv");
    a.click();
  };

  const getCSVReportFile = async () => {
    const csvWithErrors = await json2csvAsync(CSVFileProcessedData, {
      emptyFieldValue: "",
      sortHeader: (a, b) => {
        if ((a === "errors" || a === "warnings") && b !== "errors") {
          return -1;
        } else {
          return 0;
        }
      },
    });
    download(csvWithErrors);
  };

  const processCSVFile = async (file: File) => {
    setShowLoadingModal(true);
    setCSVCriticalError(false);
    await apiCall(
      async () => {
        try {
          const errorSeparator = "; ";
          if (file.size === 0) {
            throw new Error("file was not selected or is empty");
          }

          const textFile = await file.text();
          const result = await csv({
            output: "json",
            delimiter: [",", ";"],
            ignoreEmpty: true,
          }).fromString(textFile);

          //validate
          if (result.length === 0) {
            throw new Error("unable to parse csv file");
          }

          //column validation
          const colErrors: { msg: string; type: "error" | "warning" }[] = [];

          const resultCols = await csv({
            output: "json",
            delimiter: [",", ";"],
          }).fromString(textFile);
          const cols = Object.keys(resultCols[0]);

          const categoriesTree: EcmCategoryTreeNodeModel = await apiCall(
            async () => {
              return await apiService.getCategoriesTree(
                ecmEnvsSelectedItem.env,
              );
            },
            [],
            "Can't load Categories from ECM",
            null,
          );

          const cacheTreeMap = new Map();

          const cacheTree: any = function (
            path: string,
            node: EcmCategoryTreeNodeModel,
          ) {
            if (node.level > 2) {
              path += " | " + node.name;
            } else if (node.level === 2) {
              path += node.name;
            }
            if (node.level > 1) {
              cacheTreeMap.set(path, node);
            }
            if (node.children.length) {
              for (const childNode of node.children) {
                cacheTree(path, childNode);
              }
            }
          };
          cacheTree("", categoriesTree);

          const locationList: EcmLocationModel[] = await apiCall(
            async () => {
              return await apiService.getLocationByDealerId(
                ecmEnvsSelectedItem.env,
                projectsSelectedItem.dealerId,
              );
            },
            [],
            "Can't load Categories from ECM",
            null,
          );

          const locationMap = new Map();
          for (const location of locationList) {
            locationMap.set(location.name, location.marketplace_id);
          }

          const productAttributes: EcmProductUniversalAttributeModel[] =
            await apiCall(
              async () => {
                return await apiService.listEcmProductAttributes(
                  ecmEnvsSelectedItem.env,
                );
              },
              [],
              "Can't load expected for Product",
              null,
            );

          const columnMetaMap = new Map<
            string,
            {
              attribute_code: string;
              frontend_input: string;
              //display value to id map
              options: Map<string, string>;
            }
          >();
          productAttributes.forEach(el => {
            columnMetaMap.set(el.attribute_code, {
              attribute_code: el.attribute_code,
              frontend_input: el.frontend_input,
              options: el.options.reduce((map, option) => {
                map.set(option.label, option.value);
                return map;
              }, new Map<string, string>()),
            });
          });

          const expectedCols = productAttributes.map(
            attribute => attribute.attribute_code,
          );
          const requiredCols = ["seller_sku", "name"];
          const ignoreCols = [
            "category_ids",
            "ronati_ships_from",
            "mas_collection_id",
          ];
          //this fields are expected to be in csv but then they are transformed into other fields. and they are removed before sending to IQS
          const transientFields = ["location", "categories", "imageURLs"];

          const statusFieldsConfig = new Map<
            string,
            { type: string; requiredWith?: string; format?: string }
          >([
            ["sold_qty", { type: "Integer", requiredWith: "sold_price" }],
            ["sold_price", { type: "Number", requiredWith: "sold_qty" }],
            ["sold_date", { type: "Date", format: "dd/MM/yyyy" }],
            ["sold_on", { type: "String" }],
            ["reserved_qty", { type: "Integer" }],
          ]);

          expectedCols.push(
            ...transientFields,
            ...Array.from(statusFieldsConfig.keys()).filter(
              x => !expectedCols.includes(x),
            ),
          );

          const missingRequiredColumns = requiredCols.filter(
            x => !cols.includes(x),
          );
          const missingExpectedColumns = expectedCols.filter(
            x => !cols.includes(x) && !requiredCols.includes(x),
            //if requiredCols error is processed we don't to show same column in warning
          );
          const extraColumns = cols.filter(x => !expectedCols.includes(x));

          missingRequiredColumns.forEach(col => {
            colErrors.push({
              msg: `[${col}] - column is required`,
              type: "error",
            });
            setCSVCriticalError(true);
          });

          missingExpectedColumns.forEach(col => {
            //since this column will be generated by our code we don't need to put warning about it's absence
            if (ignoreCols.includes(col)) {
              return;
            }
            colErrors.push({
              msg: `[${col}] - column is expected`,
              type: "warning",
            });
            setCSVCriticalError(false);
          });

          extraColumns.forEach(col => {
            colErrors.push({
              msg: `[${col}] - column is extra, and will we skipped`,
              type: "warning",
            });
          });
          setCSVColumnReport(colErrors);
          expectedCols.push("masImages"); //this field will be removed in IQS

          //row data validation
          const uniqueSKUs: Map<string, number> = new Map<string, number>();

          result.forEach(row => {
            if (!row.seller_sku) {
              uniqueSKUs.set(row.seller_sku, 1);
              return;
            }
            const skuCount = uniqueSKUs.get(row.seller_sku);
            if (!skuCount) {
              uniqueSKUs.set(row.seller_sku, 1);
              return;
            }
            uniqueSKUs.set(row.seller_sku, skuCount + 1);
          });

          let anyErrorInFile = false;

          const numberAttributeTypes = [
            "price",
            "decimal_numbers",
            "integer_numbers",
          ];

          const numericFields = productAttributes
            .filter(productAttribute =>
              numberAttributeTypes.includes(productAttribute.frontend_input),
            )
            .map(productAttribute => productAttribute.attribute_code);

          result.forEach(row => {
            const errors: string[] = [];
            const warnings: string[] = [];
            if (row.seller_sku && uniqueSKUs.get(row.seller_sku) > 1) {
              warnings.push("[seller_sku] - is not unique in provided file");
            }
            if (!row.name) {
              errors.push("[name] - is required");
            }

            if (row.imageURLs) {
              const tempImageUrls = row.imageURLs
                .split(";")
                .map((item: string) => item.trim());
              row.masImages = [];
              for (const imageURL of tempImageUrls) {
                const res = Joi.string().uri().validate(imageURL);
                if (res.error) {
                  warnings.push(`[imageURLs] - has unexpected value`);
                  continue;
                }
                row.masImages.push(imageURL);
              }
            }

            if (row.location) {
              row.ronati_ships_from = row.location;
              const locationId = locationMap.get(row.location);
              if (locationId) {
                //should have string values as a new value or id in case it value matched with any of existing.
                row.ronati_ships_from = locationId;
              } else {
                warnings.push(
                  `[location] - has unexpected value [${row.location}] this values will be automatically added to locations`,
                );
              }
            }
            if (row.categories) {
              const categoryKeys: string[] = row.categories
                .split(";")
                .map((item: string) =>
                  item
                    .trim()
                    .split("|")
                    .map((item: string) => item.trim())
                    .join(" | "),
                );

              const categoryIds: number[] = [];
              for (const categoryKey of categoryKeys) {
                const category = cacheTreeMap.get(categoryKey);
                if (!category) {
                  warnings.push(
                    `[categories] - has unexpected value [${categoryKey}]`,
                  );
                  continue;
                }
                categoryIds.push(category.id);
              }
              row.category_ids = categoryIds;
            }

            //validate restricted dropdown values
            Object.keys(row).forEach(column => {
              const columnMeta = columnMetaMap.get(column);
              const columnValue = row[column];
              //this column is not recognized by ecm or
              //it is dropdown
              if (columnMeta?.frontend_input === "select") {
                if (!columnMeta.options.has(columnValue)) {
                  errors.push(
                    `[${column}] - has unexpected value [${columnValue}], expected options [${Array.from(
                      columnMeta.options.keys(),
                    ).join(", ")}]`,
                  );
                }
              } else if (numericFields.includes(column)) {
                if (columnValue && isNaN(columnValue)) {
                  errors.push(`if present, [${column}] must be numeric`);
                }
              }

              const statusField = statusFieldsConfig.get(column);
              if (statusField) {
                if (
                  statusField.type === "Number" &&
                  columnValue &&
                  isNaN(columnValue)
                ) {
                  errors.push(`if present, [${column}] must be numeric`);
                } else if (
                  statusField.type === "Integer" &&
                  columnValue &&
                  !Number.isInteger(Number(columnValue))
                ) {
                  errors.push(`if present, [${column}] must be integer`);
                } else if (
                  statusField.type === "Date" &&
                  columnValue &&
                  !isValid(parse(columnValue, statusField.format, new Date()))
                ) {
                  errors.push(
                    `if present, [${column}] must be valid date with format ${statusField.format}`,
                  );
                }
                if (
                  statusField.requiredWith &&
                  columnValue &&
                  !row[statusField.requiredWith]
                ) {
                  errors.push(
                    `if present, [${column}] then ${statusField.requiredWith} must be present`,
                  );
                }
              }
            });

            if (errors.length) {
              setCSVCriticalError(true);
            }
            //TODO other fields price, quantity: numeric; categories: empty or match ecm value...
            if (errors.length || warnings.length) {
              anyErrorInFile = true;
            }
            row.errors = errors.join(errorSeparator);
            row.warnings = warnings.join(errorSeparator);
          });

          const report: {
            [key: string]: { type: "error" | "warning" | "info"; cnt: number };
          } = {
            total: { cnt: result.length, type: "info" },
            errors: {
              cnt: result.filter(row => row.errors).length,
              type: "info",
            },
            warnings: {
              cnt: result.filter(row => row.warnings).length,
              type: "info",
            },
          };

          setCSVFileProcessedData(result);
          setProcessedDataForImport(
            result.map(product => {
              return expectedCols
                .filter(col => !transientFields.includes(col))
                .reduce(function (aggregatedObj, key) {
                  if (key in product) {
                    const productPropertyValue = product[key];
                    const columnMeta = columnMetaMap.get(key);
                    if (
                      columnMeta?.frontend_input === "select" &&
                      columnMeta?.options.has(productPropertyValue)
                    ) {
                      //swap display value from csv with id value(mapping is build based on data from ecm)
                      aggregatedObj[key] =
                        columnMeta.options.get(productPropertyValue);
                    } else {
                      aggregatedObj[key] = productPropertyValue;
                    }
                  }
                  return aggregatedObj;
                }, {} as { [key: string]: any });
            }),
          );
          if (anyErrorInFile) {
            result.forEach(row => {
              if (row?.errors) {
                row.errors.split(errorSeparator).forEach((error: string) => {
                  const detailsToOmit = "] - has unexpected value";
                  if (error.includes(detailsToOmit)) {
                    error = error.slice(
                      0,
                      error.indexOf(detailsToOmit) + detailsToOmit.length,
                    );
                  }
                  if (!report[error]) {
                    report[error] = { type: "error", cnt: 0 };
                  }
                  report[error].cnt = report[error].cnt ?? 0;
                  report[error].cnt += 1;
                });
              }
            });
            result.forEach(row => {
              if (row?.warnings) {
                row.warnings
                  .split(errorSeparator)
                  .forEach((warning: string) => {
                    const detailsToOmit = "] - has unexpected value";
                    if (warning.includes(detailsToOmit)) {
                      warning = warning.slice(
                        0,
                        warning.indexOf(detailsToOmit) + detailsToOmit.length,
                      );
                    }
                    if (!report[warning]) {
                      report[warning] = { type: "warning", cnt: 0 };
                    }
                    report[warning].cnt = report[warning].cnt ?? 0;
                    report[warning].cnt += 1;
                  });
              }
            });
          }
          setCSVReport(report);
        } catch (e) {
          throw new Error(`error while processing csv file ${e}`);
        }
      },
      null,
      "error while processing csv file",
      `CSV file parsed`,
    );
    setShowLoadingModal(false);
  };

  const importProductWithCSVFile = async () => {
    setShowLoadingModal(true);

    await apiCall(
      async () => {
        if (!ecmEnvsSelectedItem) {
          throw new Error("ECM environment not selected");
        }

        if (!projectsSelectedItem) {
          throw new Error("Project not selected");
        }

        if (!marketplaceAccountsSelectedItem) {
          throw new Error("Marketplace account not selected");
        }

        const result = await apiService.marketplaceImportWithFile(
          ecmEnvsSelectedItem.env,
          projectsSelectedItem.id,
          marketplaceAccountsSelectedItem.accountId,
          null,
          true,
        );

        const remappedProducts = {
          marketplaceCode: marketplaceAccountsSelectedItem.code,
          marketplace: "Ronati",
          errors: [{}],
          dealerId: marketplaceAccountsSelectedItem.accountId.split(".")[0],
          params: {
            accountId: marketplaceAccountsSelectedItem.accountId,
            env: ecmEnvsSelectedItem.env,
            dealerId: marketplaceAccountsSelectedItem.accountId.split(".")[0],
            dealerName: marketplaceAccountsSelectedItem.accountId,
            marketplaceCode: marketplaceAccountsSelectedItem.code,
            jobType: "scraping",
            importJobId: result.data.jobId,
            skipImport: false,
            type: "",
            productIdPrefix: "",
            retrieveStatuses: ["active"],
            batchMode: "batch",
          },
          listings: processedDataForImport.map((product: any) => {
            return {
              meta: {
                status: "active",
                id: `IAT_GEN_${uuidv4()}`, //sku could be not unique anymore so we need unique identifier
                url: "",
              },
              attributes: product,
            };
          }),
        };

        const jsonFile = new File(
          [JSON.stringify(remappedProducts)],
          `${result.data.jobId}.json`,
          { type: "application/json" },
        );

        await apiService.uploadFile(jsonFile, result.data.uploadFileUrl);
      },
      null,
      "Can't start import",
      `Created import job`,
    );

    await loadProjectActions();

    setShowLoadingModal(false);
  };

  const marketplaceImport = async () => {
    setShowLoadingModal(true);

    await apiCall(
      async () => {
        if (!ecmEnvsSelectedItem) {
          throw new Error("ECM environment not selected");
        }

        if (!projectsSelectedItem) {
          throw new Error("Project not selected");
        }

        if (!marketplaceAccountsSelectedItem) {
          throw new Error("Marketplace account not selected");
        }

        let retrieveMPStatuses = startRetrieveMPStatuses.length
          ? JSON.parse(JSON.stringify(startRetrieveMPStatuses)) // deepcopy
          : null;

        let importMPStatuses = startImportMPStatuses.length
          ? JSON.parse(JSON.stringify(startImportMPStatuses)) // deepcopy
          : null;

        let customIds = listingIds.length ? listingIds : null;

        let credentialsAccountId: string = null;
        let credentialsEcmEnv: string = null;

        if (useCredentialsAccount) {
          credentialsAccountId = credentialsMarketplaceAccount
            ? credentialsMarketplaceAccount.accountId
            : null;

          credentialsEcmEnv = credentialsEcmEnvSelectedItem?.env || null;

          if (!credentialsAccountId || !credentialsEcmEnv) {
            throw new Error(
              "Both credentials ECM env and Dealer account should be selected",
            );
          }
        }

        // remove "ECM " part of status because spiders don't have this part
        if (retrieveMPStatuses) {
          for (const i in retrieveMPStatuses)
            retrieveMPStatuses[i] = retrieveMPStatuses[i].replace("ECM ", "");
        }

        await apiService.marketplaceImport(
          ecmEnvsSelectedItem.env,
          projectsSelectedItem.id,
          marketplaceAccountsSelectedItem.accountId,
          retrieveMPStatuses,
          importMPStatuses,
          importIdCustomPrefix,
          customIds,
          credentialsAccountId,
          credentialsEcmEnv,
          isSingularJob,
        );
      },
      null,
      "Can't start import",
      `Created import job`,
    );

    await loadProjectActions();

    setShowLoadingModal(false);
  };

  const marketplaceAnonymousImport = async () => {
    setShowLoadingModal(true);

    await apiCall(
      async () => {
        if (!ecmEnvsSelectedItem) {
          throw new Error("ECM environment not selected");
        }

        if (!projectsSelectedItem) {
          throw new Error("Project not selected");
        }

        if (!inventoryManagerDealerId) {
          throw new Error("Inventory Manager Dealer ID is required");
        }

        if (!marketplaceDealerId) {
          throw new Error("Marketplace Dealer ID is required");
        }

        let customIds = listingIds.length ? listingIds : null;

        await apiService.marketplaceAnonymousImport(
          ecmEnvsSelectedItem.env,
          projectsSelectedItem.id,
          marketplaceAccountsSelectedItem.accountId,
          inventoryManagerDealerId,
          marketplaceDealerId,
          customIds,
        );
      },
      null,
      "Can't start import",
      `Created import job`,
    );

    await loadProjectActions();

    setShowLoadingModal(false);
  };

  const extractListings = async () => {
    setShowLoadingModal(true);

    await apiCall(
      async () => {
        if (!ecmEnvsSelectedItem) {
          throw new Error("ECM environment not selected");
        }

        if (!projectsSelectedItem) {
          throw new Error("Project not selected");
        }

        if (!marketplaceAccountsSelectedItem) {
          throw new Error("Marketplace account not selected");
        }

        let credentialsAccountId: string = null;
        let credentialsEcmEnv: string = null;

        if (useCredentialsAccount) {
          credentialsAccountId = credentialsMarketplaceAccount
            ? credentialsMarketplaceAccount.accountId
            : null;

          credentialsEcmEnv = credentialsEcmEnvSelectedItem?.env || null;

          if (!credentialsAccountId || !credentialsEcmEnv) {
            throw new Error(
              "Both credentials ECM env and Dealer account should be selected",
            );
          }
        }

        await apiService.extractListings(
          ecmEnvsSelectedItem.env,
          projectsSelectedItem.id,
          marketplaceAccountsSelectedItem.accountId,
          credentialsAccountId,
          credentialsEcmEnv,
        );
      },
      null,
      "Can't start listing extracting job",
      `Created job to extract listing`,
    );

    await loadProjectActions();

    setShowLoadingModal(false);
  };

  const getExtractedListings = async (actionId: string) => {
    setShowLoadingModal(true);

    await apiCall(
      async () => {
        if (!ecmEnvsSelectedItem) {
          throw new Error("ECM environment not selected");
        }

        if (!projectsSelectedItem) {
          throw new Error("Project not selected");
        }

        if (!marketplaceAccountsSelectedItem) {
          throw new Error("Marketplace account not selected");
        }

        if (!actionId) {
          throw new Error("Action id can't be null");
        }

        const url = await apiService.getExtractedListings(
          ecmEnvsSelectedItem.env,
          projectsSelectedItem.id,
          marketplaceAccountsSelectedItem.accountId,
          actionId,
        );

        window.open(url, "_self");
      },
      null,
      "Can't download extracted listings",
      "",
    );

    setShowLoadingModal(false);
  };

  const saveMarketplaceNotes = async (note: string) => {
    setShowLoadingModal(true);

    await apiCall(
      async () => {
        if (!marketplaceAccountsSelectedItem) {
          throw new Error("Marketplace account not selected");
        }

        await apiService.saveMarketplaceNotes(
          marketplaceAccountsSelectedItem.code,
          note,
        );
      },
      null,
      "Can't save marketplace note",
      "",
    );

    await getMarketplaceNotes();

    setShowLoadingModal(false);
  };

  const getMarketplaceNotes = async () => {
    setMarketplaceNotesLoading(true);

    const result: MarketplaceNoteModel = await apiCall(
      async () => {
        if (!marketplaceAccountsSelectedItem) {
          throw new Error("Marketplace account not selected");
        }

        return await apiService.getMarketplaceNotes(
          marketplaceAccountsSelectedItem.code,
        );
      },
      null,
      "",
      "",
    );

    setMarketplaceNotesLoading(false);
    setMarketplaceNotes(result);
  };

  const getMarketplaceRetrieveStatuses = async (): Promise<any> => {
    let allStatuses: string[] = [];
    let defaultStatuses: string[] = [];

    const response = await apiCall(
      async () => {
        if (!marketplaceAccountsSelectedItem) {
          throw new Error("Marketplace account not selected");
        }

        return apiService.getMarketplaceRetrieveStatuses(
          marketplaceAccountsSelectedItem.code,
        );
      },
      null,
      "",
      "",
    );

    for (let i in response) {
      if (response[i]["isDefault"]) {
        defaultStatuses.push(response[i]["status"]);
      }

      allStatuses.push(response[i]["status"]);
    }

    setMarketplaceRetrieveAllStatuses(allStatuses);
    setMarketplaceRetrieveDefaultStatuses(defaultStatuses);
  };

  const getMarketplaceImportStatuses = async (): Promise<any> => {
    const response: string[] = await apiCall(
      async () => {
        if (!marketplaceAccountsSelectedItem) {
          throw new Error("Marketplace account not selected");
        }

        return apiService.getMarketplaceImportStatuses(
          marketplaceAccountsSelectedItem.code,
        );
      },
      [],
      "",
      "",
    );

    setMarketplaceImportAllStatuses(response);
  };

  const importAnalysis = async () => {
    setShowLoadingModal(true);

    await apiCall(
      async () => {
        if (!ecmEnvsSelectedItem) {
          throw new Error("ECM environment not selected");
        }

        if (!projectsSelectedItem) {
          throw new Error("Project not selected");
        }

        if (!marketplaceAccountsSelectedItem) {
          throw new Error("Marketplace account not selected");
        }

        await apiService.importAnalysis(
          ecmEnvsSelectedItem.env,
          projectsSelectedItem.id,
          marketplaceAccountsSelectedItem.accountId,
        );
      },
      null,
      "Can't perform import analysis",
      null,
    );

    await loadProjectActions();

    setShowLoadingModal(false);
  };

  const loadCredentialDealers = async () => {
    setCredentialsDealerSelectedItem(null);

    if (useCredentialsAccount && credentialsEcmEnvSelectedItem) {
      setCredentialsDealerData([]);

      setCredentialsDealerLoading(true);

      const result = await apiCall(
        async () => {
          return await apiService.listEcmDealers(
            credentialsEcmEnvSelectedItem.env,
          );
        },
        [],
        "Can't load ECM dealers",
        null,
      );

      setCredentialsDealerLoading(false);
      setCredentialsDealerData(result);
    }
  };

  const loadCredentialDealerMarketplaceAccounts = async () => {
    setCredentialsMarketplaceAccount(null);

    if (useCredentialsAccount && credentialsDealerSelectedItem) {
      setCredentialsDealerLoading(true);

      const result: EcmMarketplaceAccountModel[] = await apiCall(
        async () => {
          return await apiService.listDealerAccounts(
            credentialsEcmEnvSelectedItem.env,
            String(credentialsDealerSelectedItem.id),
          );
        },
        [],
        "Can't load ECM dealers",
        null,
      );

      if (
        !result.some(item => {
          if (item.code === marketplaceAccountsSelectedItem.code) {
            setCredentialsMarketplaceAccount(item);
            return true;
          } else {
            return false;
          }
        })
      ) {
        setAlert({
          type: ALERT_STATUS.ERROR,
          message: `Credentials Dealer [${credentialsDealerSelectedItem.id}] does not have a connected marketplace [${marketplaceAccountsSelectedItem.code}]`,
        });
        setCredentialsDealerSelectedItem(null);
      }

      setCredentialsDealerLoading(false);
    }
  };

  const updateProjectSubscribers = async (ecmProject: ProjectModel) => {
    setShowLoadingModal(true);

    await apiCall(
      async () => {
        if (!ecmEnvsSelectedItem) {
          throw new Error("ECM environment not selected");
        }

        if (!projectsSelectedItem) {
          throw new Error("Project not selected");
        }

        await apiService.updateProjectSubscribers(ecmProject);
      },
      null,
      "Can't create project",
      `Project "${ecmProject?.name}" Subscribers successfully updated`,
    );

    await loadEcmProjects();
    setShowLoadingModal(false);
  };

  useEffect(() => {
    loadEcmEnvs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadEcmProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ecmEnvsSelectedItem]);

  useEffect(() => {
    loadProjectEcmDealer();
    loadMarketplaceAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsSelectedItem]);

  useEffect(() => {
    loadProjectActions();
    getMarketplaceNotes();
    loadCredentialDealers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplaceAccountsSelectedItem]);

  useEffect(() => {
    loadProjectActions();
    getMarketplaceNotes();
    loadCredentialDealers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplaceAccountsSelectedItem]);

  useEffect(() => {
    getMarketplaceRetrieveStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplaceAccountsSelectedItem]);

  useEffect(() => {
    getMarketplaceImportStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplaceAccountsSelectedItem]);

  useEffect(() => {
    loadEcmDealers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCreateModal]);

  useEffect(() => {
    loadCredentialDealers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentialsEcmEnvSelectedItem]);

  useEffect(() => {
    loadCredentialDealerMarketplaceAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentialsDealerSelectedItem]);

  const isMarketplaceAccountCredentialsSet = () => {
    if (useCredentialsAccount) {
      return true;
    }

    if (
      marketplaceAccountsSelectedItem &&
      ((marketplaceAccountsSelectedItem.status &&
        marketplaceAccountsSelectedItem.status !== "not_set") ||
        (marketplaceAccountsSelectedItem.tokenStatus &&
          marketplaceAccountsSelectedItem.tokenStatus !== "not_set"))
    ) {
      return true;
    }

    return false;
  };

  const isExistsPendingActions = (type: ActionTypeModel) => {
    return !!projectActionsData.filter(
      item => item.type === type && item.status === "pending",
    ).length;
  };

  return (
    <AlertContext.Provider value={{ setAlert }}>
      <Box>
        <ConfirmationWindow
          open={showRemoveModal}
          okButtonText="Remove"
          cancelButtonText="Cancel"
          title="Remove Project"
          text={`Are you sure that you want to remove project with name: ${
            projectsSelectedItem ? projectsSelectedItem.name : "N/A"
          }`}
          okButtonHandler={() => {
            setShowRemoveModal(false);
            removeEcmProject();
          }}
          cancelButtonHandler={() => setShowRemoveModal(false)}
        />
        <ConfirmationWindow
          open={showRemoveListingsModal}
          okButtonText="Remove"
          cancelButtonText="Cancel"
          title="Delete Listings"
          text={`Are you sure that you want to delete all listings for the following marketplace account: ${
            marketplaceAccountsSelectedItem?.accountId || "N/A"
          }?`}
          okButtonHandler={() => {
            setShowRemoveListingsModal(false);
            removeListings();
          }}
          cancelButtonHandler={() => setShowRemoveListingsModal(false)}
        />
        <ConfirmationWindow
          open={showMassMergeModal}
          okButtonText="mass merge"
          cancelButtonText="Cancel"
          title="Mass Merge"
          text={`Are you sure that you want to run mass merge?`}
          childContent={
            <Box>
              <FormControl sx={{ m: 1 }}>
                <FormLabel>Merge mode</FormLabel>
                <RadioGroup
                  value={massMergeMode}
                  onChange={event => {
                    setMassMergeMode(+event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="by sku"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="by name"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="mass-merge-accounts-label">accounts</InputLabel>
                <Select
                  labelId="mass-merge-accounts-label"
                  multiple
                  value={massMergeConnectedAccounts}
                  onChange={(
                    event: SelectChangeEvent<typeof massMergeConnectedAccounts>,
                  ) => {
                    const {
                      target: { value },
                    } = event;
                    setMassMergeConnectedAccounts(
                      // On autofill we get a stringified value.
                      typeof value === "string" ? value.split(",") : value,
                    );
                  }}
                  input={<OutlinedInput label="accounts" />}
                  renderValue={selected => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map(value => (
                        <Chip
                          key={value}
                          label={value.slice(value.indexOf(".") + 1)}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 250,
                      },
                    },
                  }}
                >
                  {marketplaceAccountsData.map(marketplaceAccount => (
                    <MenuItem
                      key={marketplaceAccount.accountId}
                      value={marketplaceAccount.accountId}
                      //style={getStyles(name, personName, theme)}
                    >
                      {marketplaceAccount.accountId.slice(
                        marketplaceAccount.accountId.indexOf(".") + 1,
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          }
          okButtonHandler={() => {
            setShowMassMergeModal(false);
            massMergeProducts();
          }}
          cancelButtonHandler={() => setShowMassMergeModal(false)}
        />
        <ConfirmationWindow
          open={showImportStatusesModal}
          okButtonText="Start Import"
          cancelButtonText="Cancel"
          title="Start Import"
          text={`Are you sure that you want to run Start Import?`}
          childContent={
            <Box marginTop={"10px"}>
              {isSpiderMarketplace() && (
                <MuiAlert severity="info">
                  Retrieve status signals to spiders what listing statuses to
                  fetch from the marketplace.
                  <br />
                  Empty = get all dealer's listings
                  <br />
                  <a href="https://ronati.atlassian.net/wiki/spaces/CTO/pages/2135556198/Marketplace+Listing+Status+Support">
                    Listing Status documentation
                  </a>
                </MuiAlert>
              )}
              {isSpiderMarketplace() && (
                <Autocomplete
                  onChange={(event, values) => {
                    setStartRetrieveMPStatuses(values as string[]);
                  }}
                  multiple
                  id="retrieve-statuses-filled"
                  value={startRetrieveMPStatuses}
                  options={marketplaceRetrieveAllStatuses} // ALL STATUSES HERE
                  freeSolo
                  renderTags={(values: readonly string[], getTagProps) =>
                    values.map((option: string, index: number) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Retrieve Status"
                    />
                  )}
                />
              )}
              {
                !startRetrieveMPStatuses.every(val =>
                  marketplaceRetrieveAllStatuses.includes(val),
                ) && (
                  <MuiAlert severity="warning">
                    WARNING: Retrieve status field contains status(es) that
                    don't exist .The import will still be sent
                  </MuiAlert>
                ) // trigger alert if we have a status that doesn't exist
              }
              <br />
              <MuiAlert severity="info">
                Import status tells IQS which ECM listing statuses to import.
                All non selected listing statuses won't be imported.
                <br />
                Empty = import all products into ECM (disable the check).
              </MuiAlert>
              <Autocomplete
                onChange={(event, values) => {
                  setStartImportMPStatuses(values as string[]);
                }}
                multiple
                id="import-statuses-filled"
                value={startImportMPStatuses}
                options={marketplaceImportAllStatuses} // ALL STATUSES HERE
                freeSolo
                renderTags={(values: readonly string[], getTagProps) =>
                  values.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Import Status"
                  />
                )}
              />
              {(!marketplaceImportAllStatuses ||
                marketplaceImportAllStatuses.length === 0) && (
                <MuiAlert severity="warning">
                  WARNING: Import status not supported for this marketplace
                </MuiAlert>
              )}
              {
                !startImportMPStatuses.every(val =>
                  marketplaceImportAllStatuses.includes(val),
                ) && (
                  <MuiAlert severity="warning">
                    WARNING: Import status field contains status(es) that don't
                    exist. The import will still be sent
                  </MuiAlert>
                ) // trigger alert if we have a status that doesn't exist
              }
              <br />
              {isSpiderMarketplace() && (
                <MuiAlert severity="info">
                  Import individual listings based on their id. If ids are
                  given, program will ignore Retrieve statuses and only import
                  given ids
                  <br />
                  Empty = disable import based on id
                </MuiAlert>
              )}
              {isSpiderMarketplace() && (
                <Autocomplete
                  onChange={(event, values) => {
                    setListingIds(values as string[]);
                  }}
                  multiple
                  id="custom-listing-ids"
                  value={listingIds}
                  options={[] as string[]}
                  freeSolo
                  renderTags={(values: readonly string[], getTagProps) =>
                    values.map((option: string, index: number) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Custom listing ids"
                    />
                  )}
                />
              )}
              <TextField
                variant="outlined"
                label="Custom prefix for products id (for testing only)"
                fullWidth={true}
                onChange={event => setImportIdCustomPrefix(event.target.value)}
                margin={"normal"}
              />
              {marketplaceRetrieveAllStatuses &&
                marketplaceRetrieveAllStatuses.length > 0 && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSingularJob}
                          onChange={() => setIsSingularJob(!isSingularJob)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Progressive import mode"
                    />
                  </FormGroup>
                )}
              {marketplaceRetrieveAllStatuses &&
                marketplaceRetrieveAllStatuses.length > 0 && (
                  <MuiAlert severity="info">
                    Progressive import is a faster way to import items. Product
                    is imported to ECM right after being scraped from a
                    marketplace (decreasing waiting time).
                  </MuiAlert>
                )}
            </Box>
          }
          okButtonHandler={() => {
            setShowImportStatusesModal(false);
            marketplaceImport();
          }}
          cancelButtonHandler={() => setShowImportStatusesModal(false)}
        />
        <ConfirmationWindow
          open={showAnonymousImportModal}
          okButtonText="Start Anonymous Import"
          cancelButtonText="Cancel"
          title="Start Anonymous Import"
          text={`Anonymous import is an import without the need to log in to the marketplace. Are you sure that you want to run Anonymous Import?`}
          childContent={
            <Box marginTop={"10px"}>
              <TextField
                variant="outlined"
                label="Inventory Manager dealer id"
                fullWidth={true}
                onChange={event =>
                  setInventoryManagerDealerId(event.target.value)
                }
                margin={"normal"}
              />
              <MuiAlert severity="info">
                Marketplace's dealer id is an identificator that's specific to
                each Marketplace. You can extract them from the marketplace's
                dealer page url. <br />
                Detailed instructions can be found here:
                <a href="https://ronati.atlassian.net/wiki/spaces/CTO/pages/2894102534/Find+dealer+seller+shop+store+ID+for+a+marketplace+account">
                  Extract dealer id documentation
                </a>
                <br />
                An example of a user id: circaeclectic
              </MuiAlert>
              <TextField
                variant="outlined"
                label="Marketplace dealer id"
                fullWidth={true}
                onChange={event => setMarketplaceDealerId(event.target.value)}
                margin={"normal"}
              />
              <MuiAlert severity="info">
                Import individual listings based on their id. If ids are given,
                program will only return the listings with the given ids
                <br />
                Empty = import all dealer's items
              </MuiAlert>
              <Autocomplete
                onChange={(event, values) => {
                  setListingIds(values as string[]);
                }}
                multiple
                id="custom-listing-ids"
                value={listingIds}
                options={[] as string[]}
                freeSolo
                renderTags={(values: readonly string[], getTagProps) =>
                  values.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Custom listing ids"
                  />
                )}
              />
            </Box>
          }
          okButtonHandler={() => {
            setShowAnonymousImportModal(false);
            marketplaceAnonymousImport();
          }}
          cancelButtonHandler={() => setShowAnonymousImportModal(false)}
        />
        <ConfirmationWindow
          open={showImportUploadFileModal}
          okButtonText="Start Import from File"
          cancelButtonText="Cancel"
          title="Start Import"
          text={`Are you sure that you want to run Start Import?`}
          okButtonDisabled={fileContent.size === 0}
          childContent={
            <Box>
              <input
                accept="application/json"
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const file = event?.target?.files?.length
                    ? event?.target?.files[0]
                    : emptyFile;
                  if (file.type !== "application/json") {
                    event.preventDefault();
                    event.target.value = "";
                    setAlert({
                      type: ALERT_STATUS.ERROR,
                      message:
                        'unsupported file type. only "json" files are supported',
                    });
                    return;
                  }

                  setFileContent(file);
                }}
              />
              <label htmlFor="raised-button-file">
                <Button component="span" color="primary">
                  Upload
                </Button>
              </label>
              <Box
                component="div"
                sx={{ display: "inline" }}
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                {fileContent.name}
              </Box>
            </Box>
          }
          okButtonHandler={() => {
            setShowImportUploadFileModal(false);
            marketplaceImportWithFile();
          }}
          cancelButtonHandler={() => setShowImportUploadFileModal(false)}
        />
        <ConfirmationWindow
          open={showImportUploadCSVFileModal}
          okButtonText="Start Import Products from CSV File"
          cancelButtonText="Cancel"
          title="Start Import"
          text={`Are you sure that you want to run Start Import?`}
          okButtonDisabled={fileContent.size === 0 || CSVCriticalError}
          childContent={
            <Box>
              <input
                accept="text/csv"
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                  const file = event?.target?.files?.length
                    ? event?.target?.files[0]
                    : emptyFile;
                  if (file.type !== "text/csv") {
                    event.preventDefault();
                    event.target.value = "";
                    setAlert({
                      type: ALERT_STATUS.ERROR,
                      message:
                        'unsupported file type. only "csv" files are supported',
                    });
                    return;
                  }

                  setFileContent(file);
                  await processCSVFile(file);
                }}
              />
              <label htmlFor="raised-button-file">
                <Button component="span" color="primary">
                  Upload
                </Button>
              </label>
              <Box
                component="div"
                sx={{ display: "inline" }}
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                {fileContent.name}
              </Box>
              <Box>
                <Grid container>
                  <Grid container item xs={12} spacing={0.5}>
                    {CSVColumnReport.length ? (
                      <Grid item xs={12} className="text">
                        <b>columns with problems:</b>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    {CSVColumnReport.map(colError => {
                      return (
                        <Grid
                          key={colError.msg}
                          item
                          xs={12}
                          className="text"
                          sx={{
                            color:
                              colError.type === "error" ? "error.main" : "",
                          }}
                        >
                          {colError.msg}
                        </Grid>
                      );
                    })}
                    {CSVColumnReport.length ? (
                      <Grid item xs={12} className="text">
                        &nbsp;
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid container item xs={12} spacing={0.5}>
                    {CSVReport.errors ? (
                      <Grid item xs={12}>
                        <b>data issues:</b>
                      </Grid>
                    ) : (
                      <></>
                    )}
                    {Object.entries(CSVReport).map(entry => {
                      return (
                        <Grid
                          key={entry[0]}
                          item
                          xs={12}
                          className="text"
                          sx={{
                            color:
                              entry[1].type === "error" ? "error.main" : "",
                          }}
                        >
                          <b>{entry[0]}</b> = {entry[1].cnt}
                        </Grid>
                      );
                    })}
                    {CSVReport.errors ? (
                      <>
                        <Grid item xs={12}>
                          &nbsp;
                        </Grid>
                        <Grid item xs={8}>
                          for details download file
                        </Grid>
                        <Grid item xs={4}>
                          <Button onClick={getCSVReportFile}>Download</Button>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          }
          okButtonHandler={() => {
            setShowImportUploadCSVFileModal(false);
            importProductWithCSVFile();
          }}
          cancelButtonHandler={() => setShowImportUploadCSVFileModal(false)}
        />
        <ConfirmationWindow
          open={showCSVBoxImportModal}
          cancelButtonText="Cancel"
          title="Start CSV Box Import"
          text={`Are you sure that you want to start CSV box Import?`}
          childContent={
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '40px', padding: '10px 20px' }}>
              <CSVBoxModalButton
                userId={user?.attributes?.email}
                licenseKey={CSVBOX_LICENSE_KEY}
                dealerId={String(projectsSelectedItem?.dealerId)}
                env={ecmEnvsSelectedItem?.env}
                afterFinishFunction={() => setShowCSVBoxImportModal(false)}
              />
            </div>
          }
          okButtonHandler={() => {
            setShowCSVBoxImportModal(false);
          }}
          cancelButtonHandler={() => setShowCSVBoxImportModal(false)}
        />
        <LoadingWindow
          open={showLoadingModal}
          title="Processing..."
          text="We are processing data, please wait..."
        />
        <CreateProjectWindow
          okButtonHandler={data => {
            setShowCreateModal(false);
            createEcmProject(data);
          }}
          cancelButtonHandler={() => setShowCreateModal(false)}
          open={showCreateModal}
          title="Create Project"
          dealers={dealersData}
          dealersLoading={dealersLoading}
        />

        <Alert
          type={alert?.type}
          isOpen={!!alert}
          removeAlert={() => {
            setAlert(null);
          }}
        >
          {alert?.message}
          {alert?.linkUrl ? (
            <>
              {" "}
              <a href={alert.linkUrl} target="_blank" rel="noreferrer">
                Details
              </a>
            </>
          ) : null}
        </Alert>

        <Box sx={{ margin: "10px 20px" }}>
          <SelectComponent
            data={ecmEnvsData}
            loading={ecmEnvsLoading}
            selectedItem={ecmEnvsSelectedItem}
            getSelectItemLabel={item => item["env"]}
            label="ECM Environment:"
            onSelect={(value: EcmEnvironmentModel) => {
              setEcmEnvsSelectedItem(value);
              localStorage.setItem("ecmEnv", value.env);
            }}
          />
          <SelectComponent
            data={projectsData}
            getSelectItemLabel={item => item["name"]}
            loading={projectsLoading}
            label="Project:"
            selectedItem={projectsSelectedItem}
            createButtonDisabled={!ecmEnvsSelectedItem}
            removeButtonDisabled={!projectsSelectedItem}
            onSelect={(value: ProjectModel) => {
              setProjectsSelectedItem(value);
              localStorage.setItem("ecmProject", value.id);
            }}
            onRemoveClick={() => setShowRemoveModal(true)}
            onCreateClick={() => setShowCreateModal(true)}
            showManageButtons={true}
          />
          <TagInputComponent
            label="Project Subscribers"
            showManageButtons={true}
            loading={projectsLoading}
            data={projectsSelectedItem?.subscribers ?? []}
            onUpdateButton={subscribers => {
              projectsSelectedItem.subscribers = subscribers ?? [];
              updateProjectSubscribers(projectsSelectedItem);
            }}
            onKeyDown={(event, error) => {
              console.log(event.target.value);
              if (
                event.key === "Enter" &&
                emailValidator.validate(event.target.value).error !== undefined
              ) {
                // Prevent's default 'Enter' behavior.
                event.stopPropagation();
                error(true);
                return;
              }
              error(false);
            }}
            helperText="valid email should be entered"
          />
          <SelectComponent
            loading={projectDealerLoading}
            label="ECM Dealer:"
            showTextInput={true}
            textValue={
              projectDealerData
                ? `${projectDealerData.firstname || ""} ${
                    projectDealerData.lastname || ""
                  }`
                : ""
            }
          />
          <SelectComponent
            data={marketplaceAccountsData}
            loading={marketplaceAccountsLoading}
            selectedItem={marketplaceAccountsSelectedItem}
            getSelectItemLabel={item => item["name"] || item["accountId"]}
            label="Marketplace account:"
            onSelect={(value: EcmMarketplaceAccountModel) => {
              setMarketplaceAccountsSelectedItem(value);
              localStorage.setItem("accountId", value.accountId);
            }}
            additionalColumn={
              marketplaceAccountsSelectedItem && (
                <div style={{ margin: "0px" }}>
                  <p
                    style={{
                      margin: "0px",
                      color: "text.secondary",
                      fontSize: "14px",
                    }}
                  >
                    Cred Status:&nbsp;
                    {marketplaceAccountsSelectedItem?.status || "not_set"}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      color: "text.secondary",
                      fontSize: "14px",
                    }}
                  >
                    Token Status:&nbsp;
                    {marketplaceAccountsSelectedItem?.tokenStatus || "not_set"}
                  </p>
                </div>
              )
            }
          />
          <Grid container>
            <Grid item xs={2}>
              <p>Use Different Credentials Account:</p>
            </Grid>
            <Grid item xs={6}>
              <Checkbox
                value={useCredentialsAccount}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                onChange={event =>
                  setUseCredentialsAccount(event.target.checked)
                }
              />
            </Grid>
          </Grid>
          {useCredentialsAccount && marketplaceAccountsSelectedItem && (
            <span>
              <SelectComponent
                data={ecmEnvsData}
                loading={ecmEnvsLoading}
                selectedItem={credentialsEcmEnvSelectedItem}
                getSelectItemLabel={item => item["env"]}
                label="Credentials ECM Environment:"
                onSelect={(value: EcmEnvironmentModel) => {
                  setCredentialsEcmEnvSelectedItem(value);
                }}
              />
              <SelectComponent
                data={credentialsDealerData}
                loading={credentialsDealerLoading}
                selectedItem={credentialsDealerSelectedItem}
                getSelectItemLabel={item => `${item.id} ${item.name}`}
                label="Credentials Dealer:"
                onSelect={(value: EcmDealerInfoModel) => {
                  setCredentialsDealerSelectedItem(value);
                }}
                additionalColumn={
                  credentialsMarketplaceAccount && (
                    <div style={{ margin: "0px" }}>
                      <p
                        style={{
                          margin: "0px",
                          color: "text.secondary",
                          fontSize: "14px",
                        }}
                      >
                        Cred Status:&nbsp;
                        {credentialsMarketplaceAccount?.status || "not_set"}
                      </p>
                      <p
                        style={{
                          margin: "0px",
                          color: "text.secondary",
                          fontSize: "14px",
                        }}
                      >
                        Token Status:&nbsp;
                        {credentialsMarketplaceAccount?.tokenStatus ||
                          "not_set"}
                      </p>
                    </div>
                  )
                }
              />
            </span>
          )}
          <ActionListComponent
            actions={
              projectActionsData && projectActionsData.length
                ? projectActionsData.sort((a, b) => {
                    return a.createdAt - b.createdAt;
                  })
                : projectActionsData
            }
            loading={projectActionsLoading}
            generatePresignedUrl={(id: string) => {
              getExtractedListings(id);
            }}
            marketplaceNotes={marketplaceNotes}
            saveMarketplaceNotes={(note: string) => {
              saveMarketplaceNotes(note);
            }}
            marketplaceNotesLoading={marketplaceNotesLoading}
          />
          <Box sx={{ marginTop: "5px" }}>
            <ButtonGroupComponent
              buttons={[
                {
                  name: "ECM Inventory",
                  disabled:
                    !marketplaceAccountsSelectedItem || projectActionsLoading,
                  handler: () => {
                    getEcmInventory();
                  },
                },
                {
                  name: "Marketplace Inventory",
                  disabled:
                    !marketplaceAccountsSelectedItem ||
                    projectActionsLoading ||
                    !isMarketplaceAccountCredentialsSet() ||
                    isExistsPendingActions("MarketplaceInventory"),
                  tooltip: getMarketplaceInventoryTooltip(),
                  handler: () => {
                    getMarketplaceInventory();
                  },
                },
                {
                  name: "Delete Listings",
                  disabled:
                    !marketplaceAccountsSelectedItem || projectActionsLoading,
                  color: "error",
                  handler: () => {
                    setShowRemoveListingsModal(true);
                  },
                },
                {
                  name: "Mass Merge",
                  disabled:
                    !marketplaceAccountsSelectedItem || projectActionsLoading,
                  handler: () => {
                    setMassMergeConnectedAccounts([]);
                    setShowMassMergeModal(true);
                  },
                },
                {
                  name: "Calculate Expecting Results",
                  disabled:
                    !marketplaceAccountsSelectedItem || projectActionsLoading,
                  handler: () => {
                    calculateExpectingResults();
                  },
                },
                {
                  name: "Extract Listings",
                  disabled:
                    !marketplaceAccountsSelectedItem ||
                    projectActionsLoading ||
                    !isMarketplaceAccountCredentialsSet(),
                  tooltip: isMarketplaceAccountCredentialsSet()
                    ? ""
                    : "Credentials not set",

                  handler: () => {
                    extractListings();
                  },
                },
                {
                  name: "Start Import",
                  disabled:
                    !marketplaceAccountsSelectedItem ||
                    projectActionsLoading ||
                    !isMarketplaceAccountCredentialsSet(),
                  tooltip: isMarketplaceAccountCredentialsSet()
                    ? ""
                    : "Credentials not set",
                  handler: () => {
                    setStartRetrieveMPStatuses(
                      marketplaceRetrieveDefaultStatuses,
                    ); // DEFAULT STATUS HERE
                    setStartImportMPStatuses([]);
                    setImportIdCustomPrefix(null);
                    setListingIds([]);
                    setShowImportStatusesModal(true);
                  },
                },
                {
                  name: "Start Anonymous Import",
                  disabled:
                    projectActionsLoading || projectsSelectedItem?.dealerId === undefined,
                  handler: () => {
                    setShowAnonymousImportModal(true);
                  },
                },
                {
                  name: "Start Import from File",
                  disabled:
                    !marketplaceAccountsSelectedItem ||
                    projectActionsLoading ||
                    !isMarketplaceAccountCredentialsSet(),
                  tooltip: isMarketplaceAccountCredentialsSet()
                    ? ""
                    : "Credentials not set",
                  handler: () => {
                    setFileContent(emptyFile);
                    setShowImportUploadFileModal(true);
                  },
                },
                {
                  name: "Start Import Products from CSV File",
                  disabled:
                    !marketplaceAccountsSelectedItem || projectActionsLoading,
                  handler: () => {
                    setFileContent(emptyFile);
                    setCSVReport({});
                    setCSVFileProcessedData(null);
                    setProcessedDataForImport(null);
                    setCSVCriticalError(false);
                    setCSVColumnReport([]);
                    setShowImportUploadCSVFileModal(true);
                  },
                },
                {
                  name: "CSV Box Import",
                  disabled:
                    projectsSelectedItem?.dealerId === undefined ||
                    projectActionsLoading,
                  handler: () => {
                    setShowCSVBoxImportModal(true);
                  }
                },
                {
                  name: "Import Analysis",
                  disabled:
                    !marketplaceAccountsSelectedItem ||
                    projectActionsLoading ||
                    !isMarketplaceAccountCredentialsSet(),
                  tooltip: isMarketplaceAccountCredentialsSet()
                    ? ""
                    : "Credentials not set",
                  handler: () => {
                    importAnalysis();
                  },
                },
              ]}
            />
          </Box>
        </Box>
      </Box>
    </AlertContext.Provider>
  );
}
